import * as React from 'react';
import Box from '@mui/material/Box';
import {DataGrid, GridToolbar} from '@mui/x-data-grid';
import Moment from 'moment';
import {useEffect, useState} from "react";
import {componentActions, measurementActions, settingsGridActions} from "../../store";
import {useDispatch} from "react-redux";
import {Button} from "@mui/material";

export default function GenVibrationGrid({projectName, nodeId, setCollectionTime, setGraphType, isCac}) {
    const dispatch = useDispatch();
    const [gridData, setGridData] = useState([]);
    const [pageSize, setPageSize] = useState(10);
    const [rowCount, setRowCount] = useState(0);
    const [sort, setSort] = useState("collectionTime,desc");
    const [page, setPage] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [thresholds, setThresholds] = useState([]);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({});

    const gridType = isCac ? "CAC" : "VIBRATION";

    function getThresholds(variableType) {
        if (thresholds) {
            return thresholds.find((element) => {
                return element.variableType === variableType;
            })
        }
        return undefined;
    }

    function getValueStatus(variableType, value) {
        const thresholdEntity = getThresholds(variableType);
        if (thresholdEntity) {
            const alertThreshold = thresholdEntity.alertThreshold;
            const dangerThreshold = thresholdEntity.dangerThreshold;
            if (value >= dangerThreshold) {
                return 'danger';
            }
            else if (value >= alertThreshold) {
                return 'warning';
            }
        }
        return 'normal';
    }

    const handleClickPlotButton = (event, row) => {
        event.stopPropagation();
        setCollectionTime(row.collectionTime);
        setGraphType("plot");
    };

    const handleSortModelChange = (sortModel) => {
        if (sortModel && sortModel.length > 0) {
            const orderBy = sortModel[0].field;
            const order = sortModel[0].sort;
            setSort(orderBy + "," + order);
        }
    };

    function showMap(latitude, longitude) {
        let url = "https://maps.google.com/?q=" + latitude + "," + longitude;
        window.open(url);
    }

    const formatDec = {
        pattern: '0.00',
    };

    const columns = [
        {field: 'id', headerName: 'ID', width: 90, hide: true},
        {
            field: 'collectionTime',
            headerName: 'Timestamp',
            width: 190,
            editable: false,
            align:'center',
            headerAlign: "center",
            valueFormatter: params =>  params.value ? new Moment(params.value).utc(false).format('L')  + " " + new Moment(params.value).utc(false).format('LTS') : "",
        },
        {
            field: 'overall',
            headerName: 'Overall G',
            type: 'number',
            align:'center',
            headerAlign: "center",
            width: 140,
            editable: false,
        },
        {
            field: 'peak',
            headerName: 'Peak G',
            type: 'number',
            align:'center',
            headerAlign: "center",
            width: 140,
            editable: false,
        },
        {
            field: 'crestFactor',
            headerName: 'Crest Factor',
            width: 140,
            type: 'number',
            numericFormat: formatDec,
            align:'center',
            headerAlign: "center",
            editable: false,
            sortable: false,
            valueFormatter: ({ value }) => value ? value.toFixed(2) : '',
            renderCell: (params) => {
                return (
                    parseFloat(parseInt((params.getValue(params.id, 'peak')/params.getValue(params.id, 'overall'))*100)/100, 10)
                );
            },
        },
        {
            field: 'lowBand',
            headerName: 'Low Band Acc',
            width: 140,
            type: 'number',
            numericFormat: formatDec,
            align:'center',
            headerAlign: "center",
            editable: false,
            sortable: false,
            hide: !isCac
        },
        {
            field: 'velocityOverall',
            headerName: 'Overall ips',
            type: 'number',
            align:'center',
            headerAlign: "center",
            width: 140,
            editable: false,
        },
        {
                field: 'altitude',
                headerName: 'Altitude',
                type: 'number',
                align:'center',
                headerAlign: "center",
                width: 140,
                editable: false,
        },
        {
            field: 'opHours',
            headerName: 'Op. Hours',
            type: 'number',
            align:'center',
            headerAlign: "center",
            width: 140,
            editable: true,
        },
        { field: 'plot', headerName: '',sortable: false, width: 100, renderCell: (params) => {
                return (
                    <Button
                        onClick={(e) => handleClickPlotButton(e, params.row)}
                        variant="contained"
                        // color="primary"
                        style={{
                            backgroundColor: "#4169E1",
                            fontSize: "12px",
                            margin: "4px",
                            alignItems: "center"
                        }}
                    >
                        Plot
                    </Button>
                );
            }
        },
        {
            field: 'city',
            headerName: 'Location',
            align:'center',
            headerAlign: "center",
            width: 190,
            editable: false,
            sortable: false,
        },
        {
            field: 'maps', headerName: '', width: 90, sortable: false, renderCell: (params) => {
                return (
                    <Button
                        onClick={() => showMap(params.row.latitude, params.row.longitude)}
                        variant="contained"
                        style={{
                            backgroundColor: "#4169E1",
                            fontSize: "12px",
                            margin: "4px",
                            alignItems: "center"
                        }}
                    >
                        Map
                    </Button>
                );
            }
        },
        {
            field: 'technician',
            headerName: 'Technician',
            align:'center',
            headerAlign: "center",
            width: 160,
            editable: false,
        },
        {
            field: 'condition',
            headerName: 'Condition',
            align:'center',
            headerAlign: "center",
            width: 160,
            editable: false,
        },
        {
            field: 'serial',
            headerName: 'S/N',
            align:'center',
            headerAlign: "center",
            width: 160,
            editable: false,
        }
    ];

    useEffect(() => {
        if (nodeId) {
            let pageable = { size: pageSize, page, sort };
            if (nodeId.substring(0,2) === "C-") {
                setIsLoading(true);
                projectName && dispatch(measurementActions.getMeasurementList({
                    projectName: projectName,
                    componentId: nodeId.substring(2),
                    noTriAxial: false,
                    pageable
                }))
                    .then((state) => {
                        if (state && state.payload) {
                            setGridData(state.payload.content);
                            setRowCount(state.payload.totalElements);
                            dispatch(componentActions.getComponentThreshold({
                                projectName: projectName,
                                componentId: nodeId.substring(2)
                            })).then((state) => {
                                if (state && state.payload) {
                                    setThresholds(state.payload);
                                    dispatch(
                                        settingsGridActions.settingsGridGet({ gridType })
                                    ).then((state) => {
                                        if (state.payload) {
                                            setColumnVisibilityModel(state.payload);
                                        }
                                        setIsLoading(false);
                                    }).catch(() => {
                                        setIsLoading(false);
                                    })
                                }
                                setIsLoading(false);
                            });
                        } else {
                            setGridData([]);
                            setRowCount(0);
                            setIsLoading(false);
                        }
                    });
            } else {
                setGridData([]);
                setIsLoading(false);
            }
        }
    }, [dispatch, nodeId, projectName, sort, page, pageSize]);

    const handleColumnVisibilityModel = (model) => {
        dispatch(
            settingsGridActions.settingsGridPut({ gridType: gridType, model: model })
        ).then(() => {
            setColumnVisibilityModel(model);
        })
    };


    if (!gridData || gridData.length === 0) {
        return;
    }
    return (
        <div>
            <Box height="69vh" m="-10px 0 0 0">
                <DataGrid
                    loading={isLoading}
                    rows={gridData}
                    columns={columns}
                    onSortModelChange={handleSortModelChange}
                    sortingMode="server"
                    columnVisibilityModel={columnVisibilityModel}
                    onCellEditCommit={(id, field, value ) => {
                        setIsLoading(true);
                        dispatch(measurementActions.updateOperationalHours({
                            projectName: projectName,
                            componentId: nodeId.substring(2),
                            id: id.id,
                            opHours: id.value})
                        ).catch((error) => {
                            console.error('Error updating cell value:', error);
                        }).finally(() => {
                            setIsLoading(false);
                        })
                    }}
                    onColumnVisibilityModelChange={(newModel) => {
                            handleColumnVisibilityModel(newModel);
                        }
                    }
                    onPageChange={(newPage) => setPage(newPage)}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    pagination
                    paginationMode="server"
                    pageSize={pageSize}
                    rowCount={rowCount}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: {pageSize},
                            },
                        },
                        sorting: {
                            sortModel: [{ field: 'collectionTime', sort: 'desc' }],
                        },
                    }}

                    getCellClassName={(params) => {
                        if (params.field === 'overall') {
                            return getValueStatus('Acceleration', params.value);
                        }
                        if (params.field === 'peak') {
                            return getValueStatus('Peak', params.value);
                        }
                        if (params.field === 'velocityOverall') {
                            return getValueStatus('Velocity', params.value);
                        }
                        if (params.field === 'LowBand') {
                            return getValueStatus('LowBand', params.value);
                        }
                        if (params.field === 'opHours') {
                            return 'info';
                        }
                    }}

                    sx={{
                        '& .normal': {
                        },
                        '& .info': {
                            backgroundColor: '#007bff30',
                        },
                        '& .warning': {
                            backgroundColor: '#cccc0022',
                        },
                        '& .danger': {
                            backgroundColor: '#ff000022',
                        },
                    }}

                    components={{ Toolbar: GridToolbar }}
                    checkboxSelection
                    disableRowSelectionOnClick
                />
            </Box>
        </div>
    );
}

export const BACKEND_SERVER_URL = process.env.REACT_APP_BASE_URL + "/api";

// Account Paths:
export const API_ACCOUNT = `${BACKEND_SERVER_URL}/accounts`;

// Admin Paths:
export const API_ADMIN = `${BACKEND_SERVER_URL}/admin`;
export const API_ADMIN_ACCOUNTS = `${BACKEND_SERVER_URL}/admin/accounts`;
export const API_ADMIN_ROLES = `${BACKEND_SERVER_URL}/admin/roles`;

// Auth Paths:
export const API_GET_AUTHORIZATION_TOKEN = `${BACKEND_SERVER_URL}/authorization/token`;

// Project Paths:
export const API_GET_ACCOUNT_PROJECTS = `${BACKEND_SERVER_URL}/accounts/projects`;
export const API_GET_PROJECTS_PAGES = `${BACKEND_SERVER_URL}/projects/pages`;
export const API_GET_PROJECT_HIERARCHY = `${BACKEND_SERVER_URL}/projects`;

// Public Paths:
export const API_PUBLIC_ACTIVATION_INIT = `${BACKEND_SERVER_URL}/public/activation/init`;
export const API_PUBLIC_ACTIVATION_FINISH = `${BACKEND_SERVER_URL}/public/activation/finish`;
export const API_PUBLIC_PASSWORD_RESET = `${BACKEND_SERVER_URL}/public/passwordReset`;
export const API_PUBLIC_PASSWORD_RESET_INIT = `${BACKEND_SERVER_URL}/public/passwordReset/init`;
export const API_PUBLIC_PASSWORD_RESET_FINISH = `${BACKEND_SERVER_URL}/public/passwordReset/finish`;

export const API_GET_MEASUREMENT_LIST = `${BACKEND_SERVER_URL}/measurements`;
export const API_GET_FFT = `${BACKEND_SERVER_URL}/measurements`;
export const API_GET_THERMALDATA = `${BACKEND_SERVER_URL}/thermal`;

export const API_FAT_READING_PAGE = `${BACKEND_SERVER_URL}/fatReading`;
export const API_ELECTRICAL_READING_PAGE = `${BACKEND_SERVER_URL}/electricalReading`;

export const API_GET_MODEL_STATUS = `${BACKEND_SERVER_URL}/models`;

export const API_GET_COMPONENT_THRESHOLD = `${BACKEND_SERVER_URL}/components`;

export const API_PUT_OP_HOURS = `${BACKEND_SERVER_URL}/processed`;

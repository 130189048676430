// noinspection JSUnresolvedReference

import {Box, Button, Chip} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Moment from "moment/moment";
import {getReportURLByNodeIdAndNodeType, nodeActions, settingsGridActions} from "../../store";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { saveAs } from 'file-saver';

import * as React from "react";
import {authHeader} from "../../helpers";

const statusColor = (status) => {
  switch (status) {
    case "GOOD":
      return "success";
    case "ALERT":
      return "warning";
    case "DANGER":
      return "error";
    default:
      return "default";
  }
};


export default function ModelStatusGrid({
  projectName,
  nodeId,
  setNodeId,
  nodeType,
  setSelected,
  expanded,
  setExpanded,
}) {
  const dispatch = useDispatch();



  // DataGrid {{
  const columns = [
    {field: "id", disableColumnSorting: true, filterable: false, hide: true},
    {field: "parentId", hide: true},
    {
      field: "name",
      align: "left",
      editable: false,
      headerAlign: "center",
      headerName: "name",
      width: 200,
    },
    {
      field: "lastReading",
      align: "left",
      editable: false,
      filterable: false,
      headerAlign: "center",
      headerName: "Last Reading TimeStamp",
      valueFormatter: (params) =>
          params.value ? new Moment(params.value).utc(false).format('L')  + " " + new Moment(params.value).utc(false).format('LTS') : "",
      width: 200,
    },
    {
      field: "nodeStatus",
      align: "center",
      editable: false,
      filterable: false,
      headerAlign: "center",
      headerName: "Status",
      sortable: false,
      width: 160,
      renderCell: (params) => {
          const color = statusColor(params.row.nodeStatus);
        return (
            <Chip
                color={color}

                label={params.row.nodeStatus === 'DANGER'? 'ALERT' : params.row.nodeStatus}
                sx={{borderWidth: "2px", fontWeight: "bold"}}
            />
        );
      },
    },
    {
      field: "location",
      align: "center",
      editable: false,
      filterable: false,
      headerAlign: "center",
      headerName: "Location",
      width: 190,
    },
    {
      field: "buttons",
      filterable: false,
      headerName: "",
      width: 250,
      renderCell: (params) => {
        return (
            <span>
            <Button
                onClick={() => onClickSelect(params.row)}
                variant="contained"
                style={{
                  alignItems: "center",
                  backgroundColor: "#0077CC",
                  fontSize: "12px",
                  fontWeight: "bold",
                  margin: "4px",
                }}
            >
              Select
            </Button>
            <Button
                onClick={() => showMap(params.row.latitude, params.row.longitude)}
                variant="contained"
                style={{
                  alignItems: "center",
                  backgroundColor: "#0077CC",
                  fontSize: "12px",
                  fontWeight: "bold",
                  margin: "4px",
                }}
            >
              Map
            </Button>
            <Button
                 onClick={() => onClickReport(params.row)}
                 variant="contained"
                 style={{
                     alignItems: "center",
                     backgroundColor: "#0077CC",
                     fontSize: "12px",
                     fontWeight: "bold",
                     margin: "4px",
                 }}
             >
              Report
            </Button>
          </span>
        );
      },
    },
  ];
  const [isLoading, setIsLoading] = useState(true);
  const [pageSize, setPageSize] = useState(25);
  const [page, setPage] = useState(0);
  const [sort, setSort] = useState("lastReading,desc");
  const [filterByColumn, setFilterByColumn] = useState("");
  const [filterByValue, setFilterByValue] = useState("");
  const [rowCount, setRowCount] = useState(0);
  const [rows, setRows] = useState([]);

  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});

  useEffect(() => {

      let pageable = {size: pageSize, page, sort};

      if (filterByColumn && filterByValue) {
          pageable[filterByColumn] = filterByValue;
      }

      setIsLoading(true);
      const getNodeId = (nodeId) => (nodeId ? nodeId.substring(2) : nodeId);
      dispatch(
          nodeActions.getNodeStatusList({
              projectName,
              nodeId: nodeId ? getNodeId(nodeId) : undefined,
              nodeType,
              pageable,
          })
      ).then((state) => {
          if (state.payload) {
              setRows(state.payload.content);
              setRowCount(state.payload.totalElements);
              dispatch(
                  settingsGridActions.settingsGridGet({gridType: nodeType})
              ).then((state) => {
                  if (state.payload) {
                      setColumnVisibilityModel(state.payload);
                  }
              })
          }
      }).finally(() => {
          setIsLoading(false);
      });
  }, [
    filterByColumn,
    filterByValue,
    projectName,
    nodeId,
    nodeType,
    pageSize,
    page,
    sort,
    dispatch,
  ]);

  const handleSortModelChange = (sortModel) => {
    if (sortModel && sortModel.length > 0) {
      const orderBy = sortModel[0].field;
      const order = sortModel[0].sort;
      setSort(orderBy + "," + order);
    }
  };

  const handleColumnVisibilityModel = (model) => {
      dispatch(
          settingsGridActions.settingsGridPut({ gridType: nodeType, model: model })
      ).then(() => {
          setColumnVisibilityModel(model);
      })
  };

  const handleFilterModelChange = (filterModel) => {
    if (filterModel && filterModel.items && filterModel.items.length > 0) {
      setFilterByColumn(filterModel.items[0].columnField);
      setFilterByValue(filterModel.items[0].value);
    } else {
      setFilterByColumn("");
      setFilterByValue("");
    }
  };
  // }} DataGrid

  const onClickSelect = (row) => {
    const newSelected = (row.nodeType === "SUB_SYSTEM" ? "U" : row.nodeType.substring(0, 1)) + "-" + row.id;
    const newExpanded = [
      ...new Set([].concat([newSelected], [nodeId], expanded)),
    ];

    setNodeId(newSelected);
    setSelected(newSelected);
    setExpanded(newExpanded);
  };

  const onClickReport = async (row) => {
      setIsLoading(true);
      const rowNodeType = (row.nodeType === "SUB_SYSTEM" ? "U" : row.nodeType.substring(0, 1));
      try {
          const url = getReportURLByNodeIdAndNodeType(projectName, row.id, rowNodeType);
          const requestOptions = {
              headers: authHeader(url)
          };
          const response = await fetch(url, requestOptions); // Replace with your API endpoint
          if (response.ok) {
              const blob = await response.blob();
              let filename = response.headers.get('Content-Disposition')?.split('filename=')[1] || 'report.csv'; // Extract filename from headers or use a default
              filename = filename.replace(/"/g, '');
              saveAs(blob, filename);
          }
          setIsLoading(false);
      } catch (error) {
          setIsLoading(false);
      }
  }

  const showMap = (latitude, longitude) => {
    let url = "https://maps.google.com/?q=" + latitude + "," + longitude;
    window.open(url);
  };

  return (
      <Box sx={{width: "100%"}}>
        <Box height="70vh">
          <DataGrid
              columns={columns}
              filterMode="server"
              loading={isLoading}
              columnVisibilityModel={columnVisibilityModel}
              onColumnVisibilityModelChange={(newModel) => {
                  handleColumnVisibilityModel(newModel);
              }
              }
              onFilterModelChange={handleFilterModelChange}
              onPageChange={(newPage) => setPage(newPage)}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              onSortModelChange={handleSortModelChange}
              pageSize={pageSize}
              pagination
              paginationMode="server"
              rowCount={rowCount}
              rows={rows}
              rowsPerPageOptions={[10, 25, 50, 100]}
              sortingMode="server"
              sx={{
                "& .normal": {},
                "& .warning": {
                  backgroundColor: "#cccc0022",
                },
                "& .danger": {
                  backgroundColor: "#ff000022",
                },
              }}
          />
        </Box>
      </Box>
  );
}

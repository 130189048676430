import {Breadcrumbs, Link} from "@mui/material";
import * as React from "react";
import AirplanemodeActiveIcon from "@mui/icons-material/AirplanemodeActive";
import {Apps, MenuBook, NumbersRounded, Settings, Archive} from "@mui/icons-material";

const getNodeIcon = (nodeType) => {
    switch (nodeType) {
        case "PROJECT":
            return <Archive sx={{ mr: 0.5 }} fontSize="inherit" />;
        case "MODEL":
            return <AirplanemodeActiveIcon sx={{ mr: 0.5 }} fontSize="inherit" />;
        case "TAIL":
            return <NumbersRounded sx={{ mr: 0.5 }} fontSize="inherit" />;
        case "SYSTEM":
            return <MenuBook sx={{ mr: 0.5 }} fontSize="inherit" />;
        case "SUB_SYSTEM":
            return <Apps sx={{ mr:0.5 }} fontSize="inherit" />;
        case "COMPONENT":
            return <Settings sx={{ mr: 0.5 }} fontSize="inherit" />;
        default:
            return <AirplanemodeActiveIcon sx={{ mr: 0.5 }} fontSize="inherit" />;
    }
};

const BreadcrumbsBar = ({ breadCrumbList, nodeId, setNodeId, expanded, setSelected, setExpanded }) => {
    const onClickSelect = (event, row) => {
        console.log("onClickSelect", row);
        event.preventDefault();
        if (row.nodeType !== "PROJECT") {
            const newSelected = (row.nodeType === "SUB_SYSTEM" ? "U" : row.nodeType.substring(0, 1)) + "-" + row.id;
            const newExpanded = [
                ...new Set([].concat([newSelected], [nodeId], expanded)),
            ];

            setNodeId(newSelected);
            setSelected(newSelected);
            setExpanded(newExpanded);
        } else {
            setNodeId(null);
            setSelected([]);
            setExpanded([]);
        }
    };
    
    if (breadCrumbList && breadCrumbList.length > 0) {
        return (
            <Breadcrumbs separator="/"  sx={{ margin: 0.5 }} size="sm" aria-label="breadcrumb">
                {breadCrumbList.map(i => (
                    <Link underline="hover" sx={{ display: 'flex', alignItems: 'center' }} color="inherit"
                                    href="/" onClick={(event) => onClickSelect(event, i) } >
                        { getNodeIcon(i.nodeType) }
                        {i.name}
                    </Link>
                ))}
            </Breadcrumbs>
        )
    }
}

export default BreadcrumbsBar;
import * as React from 'react';
import {styled, useTheme} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

import {TreeView} from '@mui/x-tree-view/TreeView';
import {TreeItem, treeItemClasses} from '@mui/x-tree-view/TreeItem';
import {MenuBook, NumbersRounded, Settings, Apps} from "@mui/icons-material";

const StyledTreeItemRoot = styled(TreeItem)(({theme}) => ({
    color: theme.palette.text.secondary,
    [`& .${treeItemClasses.content}`]: {
        color: theme.palette.text.secondary,
        borderTopRightRadius: theme.spacing(2),
        borderBottomRightRadius: theme.spacing(2),
        paddingRight: theme.spacing(1),
        fontWeight: theme.typography.fontWeightBold,
        '&.Mui-expanded': {
            fontWeight: theme.typography.fontWeightBold,
        },
        '&:hover': {
            backgroundColor: theme.palette.action.hover,
        },
        '&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused': {
            backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
            color: 'var(--tree-view-color)',
        },
        [`& .${treeItemClasses.label}`]: {
            fontWeight: 'inherit',
            color: 'inherit',
        },
    },
    [`& .${treeItemClasses.group}`]: {
        marginLeft: 0,
        [`& .${treeItemClasses.content}`]: {
            paddingLeft: theme.spacing(2),
        },
    },
}));

const StyledTreeItem = React.forwardRef(function StyledTreeItem(props, ref) {
    const theme = useTheme();
    const {
        bgColor,
        bgColorForDarkMode,
        color,
        colorForDarkMode,
        labelIcon: LabelIcon,
        labelInfo,
        labelText,
        ...other
    } = props;
    const key = props.nodeId;
    const styleProps = {
        '--tree-view-color': theme.palette.mode !== 'dark' ? color : colorForDarkMode,
        '--tree-view-bg-color':
            theme.palette.mode !== 'dark' ? bgColor : bgColorForDarkMode,
    };

    return (
        <StyledTreeItemRoot
            key={key}
            label={
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        p: 0.5,
                        pr: 0,
                    }}
                >
                    <Box component={LabelIcon} color="inherit" sx={{mr: 1}}/>
                    <Typography variant="body2" sx={{fontWeight: 'inherit', flexGrow: 1}}>
                        {labelText}
                    </Typography>
                    <Typography variant="caption" color="inherit">
                        {labelInfo}
                    </Typography>
                </Box>
            }
            style={styleProps}
            {...other}
            ref={ref}
        />
    );
});

function generateComponentItemList(componentList) {
    return (
        componentList.map(component =>
            <StyledTreeItem
                key={"C-" + component.id}
                nodeId={"C-" + component.id}
                labelText={component.name}
                labelInfo={component.count}
                labelIcon={Settings}
                color="#a250f5"
                bgColor="#f3e8fd"
                colorForDarkMode="#D9B8FB"
                bgColorForDarkMode="#100719"
            >
            </StyledTreeItem>
        )
    );
}

function generateSubSystemItemList(subSystemList) {
    return (
        subSystemList.map(subSystem =>
            <StyledTreeItem
                key={"U-" + subSystem.id}
                nodeId={"U-" + subSystem.id}
                labelText={subSystem.name}
                labelInfo={subSystem.count}
                labelIcon={Apps}
                color="#a250f5"
                bgColor="#f3e8fd"
                colorForDarkMode="#D9B8FB"
                bgColorForDarkMode="#100719"
            >
                {subSystem.childrenList && subSystem.childrenList.length > 0 && generateComponentItemList(subSystem.childrenList)}

            </StyledTreeItem>
        )
    );
}
function generateSystemItemList(systemList) {
    return (
        systemList.map(system =>
            <StyledTreeItem
                key={"S-" + system.id}
                nodeId={"S-" + system.id}
                labelText={system.name}
                labelInfo={system.count}
                labelIcon={MenuBook}
                color="#e3742f"
                bgColor="#fcefe3"
                colorForDarkMode="#FFE2B7"
                bgColorForDarkMode="#191207"
            >
                {system.childrenList && system.childrenList.length > 0 && generateSubSystemItemList(system.childrenList)}

            </StyledTreeItem>
        )
    );
}

function generateTailItemList(tailList) {
    return (
        tailList.map(tail =>
            <StyledTreeItem
                key={"T-" + tail.id}
                nodeId={"T-" + tail.id}
                labelText={tail.name}
                labelInfo={tail.count}
                labelIcon={NumbersRounded}
                color="#1a73e8"
                bgColor="#e8f0fe"
                colorForDarkMode="#B8E7FB"
                bgColorForDarkMode="#071318"
            >
                {tail.childrenList && tail.childrenList.length > 0 && generateSystemItemList(tail.childrenList)}

            </StyledTreeItem>
        )
    );
}

function generateAircraftModelItemList(aircraftModelList) {
    return (aircraftModelList.map(model =>
            <StyledTreeItem
                key={"M-" + model.id}
                nodeId={"M-" + model.id}
                labelText={model.name}
                labelIcon={AirplanemodeActiveIcon}
                labelInfo={model.count}
            >
                {model.childrenList && model.childrenList.length > 0 && generateTailItemList(model.childrenList)}
            </StyledTreeItem>
        )
    )
}

export default function StyledTreeView({aircraftModelList, expanded, setExpanded, selected, setSelected, setNodeId, setCollectionTime, setGraphType}) {
    const handleSelect = (event, nodeIds) => {
        console.log("handleSelect", event, nodeIds);
        if (selected.includes(nodeIds[0])) {
            setSelected([]);
            setExpanded([]);
            setNodeId(null);
        } else {
            setSelected(nodeIds);
            setNodeId(nodeIds);
        }
        setGraphType(null);
        setCollectionTime(null);
    }
    const handleExpanded = (event, nodeIds) => {
        setExpanded(nodeIds);
    }
    return (
        <TreeView
            aria-label="project"
            defaultCollapseIcon={<ArrowDropDownIcon/>}
            defaultEndIcon={<div style={{width: 32}}/>}
            defaultExpandIcon={<ArrowRightIcon/>}
            defaultSelected={[]}
            expanded={expanded}
            onNodeSelect={handleSelect}
            onNodeToggle={handleExpanded}
            selected={selected}
            sx={{height: '70vh', flexGrow: 1, maxWidth: 400, overflowY: 'auto'}}
        >
            {generateAircraftModelItemList(aircraftModelList)}
        </TreeView>
    );
}

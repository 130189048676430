import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

import {fetchWrapper, objToQueryString} from '../../helpers';
import {API_GET_MEASUREMENT_LIST, API_PUT_OP_HOURS} from "../../constants/network";
import {API_GET_FFT} from "../../constants/network";

// create slice
const name = 'account';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const measurementSlice = createSlice({name, initialState, extraReducers});

// exports

export const measurementActions = {...measurementSlice.actions, ...extraActions};

// implementation

function createInitialState() {
    return {}
}

function createExtraActions() {
    return {
        getMeasurementList: getMeasurementList(),
        getTrend: getTrend(),
        getTrendVel: getTrendVel(),
        getBaselineData: getBaselineData(),
        getTWF: getTWF(),
        getTWFCompare: getTWFCompare(),
        requestProcessMeasurement: requestProcessMeasurement(),
        getThermalList: getThermalList(),
        getThermalTrend: getThermalTrend(),
        getVisualList: getVisualList(),
        getFFT:getFFT(),
        updateOperationalHours:updateOperationalHours()
    };

    function getMeasurementList() {
        return createAsyncThunk(
            `/measurementList`,
            async ({
                       projectName,
                       componentId,
                       pageable,
                       noTriAxial= false
                   }) => await fetchWrapper.get(`${API_GET_MEASUREMENT_LIST}/${projectName}/${componentId}/overalls?noTriAxial=${noTriAxial}&${objToQueryString(pageable)}`, null)
        );
    }

    function getBaselineData() {
        return createAsyncThunk(
            `/baselineData`,
            async ({
                       projectName,
                       componentId
                   }) => await fetchWrapper.get(`${API_GET_MEASUREMENT_LIST}/${projectName}/${componentId}/baseline`, null)
        );
    }

    function getTrend() {
        return createAsyncThunk(
            `/baselineData`,
            async ({
                       projectName,
                       componentId
                   }) => await fetchWrapper.get(`${API_GET_MEASUREMENT_LIST}/${projectName}/${componentId}/trend`, null)
        );
    }

    function getTrendVel() {
        return createAsyncThunk(
            `/trendVel`,
            async ({
                       projectName,
                       componentId
                   }) => await fetchWrapper.get(`${API_GET_MEASUREMENT_LIST}/${projectName}/${componentId}/trendVel`, null)
        );
    }

    function getTWF() {
        return createAsyncThunk(
            `/twf`,
            async ({
                       projectName,
                       componentId,
                       collectionTime
                   }) => await fetchWrapper.get(`${API_GET_MEASUREMENT_LIST}/${projectName}/${componentId}/data/${collectionTime}`, null)
        );
    }

    function getFFT() {
        return createAsyncThunk(
            `/fft`,
            async ({
                       projectName,
                       componentId,
                       collectionTime,
                       type
                   }) => await fetchWrapper.get(`${API_GET_FFT}/${projectName}/${componentId}/fft/${collectionTime}?type=${type}`, null)
        );
    }

    function getTWFCompare() {
        return createAsyncThunk(
            `/twfCompare`,
            async ({
                       projectName,
                       componentId,
                       collectionTime
                   }) => await fetchWrapper.get(`${API_GET_MEASUREMENT_LIST}/${projectName}/${componentId}/dataCompare/${collectionTime}`, null)
        );
    }

    function requestProcessMeasurement() {
        return createAsyncThunk(
            `/requestProcessMeasurement`,
            async ({
                   }) => await fetchWrapper.post(`${API_GET_MEASUREMENT_LIST}`, null)
        );
    }

    function getThermalList() {
        return createAsyncThunk(
            `/thermalList`,
            async ({
                       projectName,
                       componentId
                   }) => await fetchWrapper.get(`${API_GET_MEASUREMENT_LIST}/${projectName}/${componentId}/thermalImage`, null)
        );
    }

    function getThermalTrend() {
        return createAsyncThunk(
            `/thermalTrend`,
            async ({
                       projectName,
                       componentId
                   }) => await fetchWrapper.get(`${API_GET_MEASUREMENT_LIST}/${projectName}/${componentId}/thermalTrend`, null)
        );
    }

    function getVisualList() {
        return createAsyncThunk(
            `/visualList`,
            async ({
                       projectName,
                       componentId
                   }) => await fetchWrapper.get(`${API_GET_MEASUREMENT_LIST}/${projectName}/${componentId}/visualImage`, null)
        );
    }

    function updateOperationalHours() {
        return createAsyncThunk(
            `/opHours`,
            async ({
                       projectName,
                       componentId,
                       id,
                       opHours
                   }) => await fetchWrapper.put(`${API_PUT_OP_HOURS}/${projectName}/${componentId}/data/${id}/hours/${opHours}`, null)
        );
    }

}

function createExtraReducers() {
    return {
        ...getMeasurementList(),
        ...getBaselineData(),
        ...getTrend(),
        ...getTrendVel(),
        ...getTWF(),
        ...requestProcessMeasurement(),
        ...getThermalList(),
        ...getThermalTrend(),
        ...getVisualList(),
        ...getFFT(),
        ...updateOperationalHours()
    };

    function getMeasurementList() {
        let {pending, fulfilled, rejected} = extraActions.getMeasurementList;
        return {
            [pending]: () => {
            },
            [fulfilled]: (state, action) => {
                return action.payload;
            },
            [rejected]: (state, action) => {
                state.account = {error: action.error};
                state.account.loading = false;
            }
        };
    }

    function getBaselineData() {
        let {pending, fulfilled, rejected} = extraActions.getBaselineData;
        return {
            [pending]: () => {
            },
            [fulfilled]: (state, action) => {
                return action.payload;
            },
            [rejected]: (state, action) => {
                state.account = {error: action.error};
                state.account.loading = false;
            }
        };
    }

    function getTrend() {
        let {pending, fulfilled, rejected} = extraActions.getTrend;
        return {
            [pending]: () => {
            },
            [fulfilled]: (state, action) => {
                return action.payload;
            },
            [rejected]: (state, action) => {
                state.account = {error: action.error};
                state.account.loading = false;
            }
        };
    }

    function getTrendVel() {
        let {pending, fulfilled, rejected} = extraActions.getTrendVel;
        return {
            [pending]: () => {
            },
            [fulfilled]: (state, action) => {
                return action.payload;
            },
            [rejected]: (state, action) => {
                state.account = {error: action.error};
                state.account.loading = false;
            }
        };
    }

    function getTWF() {
        let {pending, fulfilled, rejected} = extraActions.getTWF;
        return {
            [pending]: () => {
            },
            [fulfilled]: (state, action) => {
                return action.payload;
            },
            [rejected]: (state, action) => {
                state.account = {error: action.error};
                state.account.loading = false;
            }
        };
    }
    function getFFT() {
        let {pending, fulfilled, rejected} = extraActions.getFFT;
        return {
            [pending]: () => {
            },
            [fulfilled]: (state, action) => {
                return action.payload;
            },
            [rejected]: (state, action) => {
                state.account = {error: action.error};
                state.account.loading = false;
            }
        };
    }
    function getTWFCompare() {
        let {pending, fulfilled, rejected} = extraActions.getTWFCompare;
        return {
            [pending]: () => {
            },
            [fulfilled]: (state, action) => {
                return action.payload;
            },
            [rejected]: (state, action) => {
                state.account = {error: action.error};
                state.account.loading = false;
            }
        };
    }
    function requestProcessMeasurement() {
        let {pending, fulfilled, rejected} = extraActions.requestProcessMeasurement;
        return {
            [pending]: () => {
            },
            [fulfilled]: () => {
            },
            [rejected]: () => {
            }
        };
    }

    function getThermalList() {
        let {pending, fulfilled, rejected} = extraActions.getThermalList;
        return {
            [pending]: () => {
            },
            [fulfilled]: (state, action) => {
                return action.payload;
            },
            [rejected]: () => {
            }
        };
    }

    function getThermalTrend() {
        let {pending, fulfilled, rejected} = extraActions.getThermalTrend;
        return {
            [pending]: () => {
            },
            [fulfilled]: (state, action) => {
                return action.payload;
            },
            [rejected]: (state, action) => {
                state.account = {error: action.error};
                state.account.loading = false;
            }
        };
    }

    function getVisualList() {
        let {pending, fulfilled, rejected} = extraActions.getVisualList;
        return {
            [pending]: () => {
            },
            [fulfilled]: (state, action) => {
                return action.payload;
            },
            [rejected]: () => {
            }
        };
    }

    function updateOperationalHours() {
        let {pending, fulfilled, rejected} = extraActions.updateOperationalHours;
        return {
            [pending]: () => {
            },
            [fulfilled]: (state, action) => {
            },
            [rejected]: () => {
            }
        };
    }

}

export default measurementSlice;
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {fetchWrapper, objToQueryString} from "../../helpers";
import { BACKEND_SERVER_URL } from "../../constants/network";

// Create Slice:
const name = "node";
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const nodeSlice = createSlice({ name, initialState, extraReducers });

// Exports:
export const nodeActions = { ...nodeSlice.actions, ...extraActions };

function getURLByNodeIdAndNodeType(projectName, nodeId, nodeType) {
  let url = BACKEND_SERVER_URL;
  switch (nodeType) {
    case "PROJECT":
      return `${url}/projects/${projectName}`;
    case "M":
      url = url + "/models";
      break;
    case "T":
      url = url + "/tails";
      break;
    case "S":
      url = url + "/systems";
      break;
    case "U":
      url = url + "/subSystems";
      break;
    default:
      url = url + "/components";
      break;
  }
  return `${url}/${projectName}/${nodeId}`;
}


function getURLByNodeIdAndNodeTypeStatus(projectName, nodeId, nodeType, pageable) {
  return  getURLByNodeIdAndNodeType(projectName, nodeId, nodeType) + "/status?" + `${objToQueryString(pageable)}`
}

export function getReportURLByNodeIdAndNodeType(projectName, nodeId, nodeType) {
  return  getURLByNodeIdAndNodeType(projectName, nodeId, nodeType) + "/report";
}

function createInitialState() {
  return {};
}

function createExtraActions() {
  function getNodeDetail() {
    return createAsyncThunk(
        "/nodeDetail",
        async ({ projectName, nodeId, nodeType }) =>
            await fetchWrapper.get(
                getURLByNodeIdAndNodeType(projectName, nodeId, nodeType),
                null
            )
    );
  }

  function getNodeStatusList() {
    return createAsyncThunk(
      "/nodeStatus",
      async ({ projectName, nodeId, nodeType, pageable }) =>
        await fetchWrapper.get(
          getURLByNodeIdAndNodeTypeStatus(projectName, nodeId, nodeType, pageable),
          null
        )
    );
  }

   return {
    getNodeDetail: getNodeDetail(),
    getNodeStatusList: getNodeStatusList()
  };
}

function createExtraReducers() {
  return {
    ...getNodeDetail(),
    ...getNodeStatusList()
  };

  function getNodeDetail() {
    let { pending, fulfilled, rejected } = extraActions.getNodeDetail;
    return {
      [pending]: () => {},
      [fulfilled]: (state, action) => {
        console.log("Action", action);
        return action.payload;
      },
      [rejected]: (state, action) => {
        state.account = { error: action.error };
        state.account.loading = false;
      },
    };
  }

  function getNodeStatusList() {
    let { pending, fulfilled, rejected } = extraActions.getNodeStatusList;
    return {
      [pending]: () => {},
      [fulfilled]: (state, action) => {
        return action.payload;
      },
      [rejected]: (state, action) => {
        state.account = { error: action.error };
        state.account.loading = false;
      },
    };
  }
}

export default nodeSlice;

import {Box, useTheme} from "@mui/material";
import {tokens} from "../../theme";
import StyledTreeView from "../../components/StyledTreeView";
import ProjectSelect from "../../components/Project";
import Sidebar from "../global/Sidebar";
import Topbar from "../global/Topbar";
import * as React from "react";
import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {projectActions, nodeActions} from "../../store";
import DetailWindow from "../component/DetailWindow";
import ModelDetailWindow from "../model/ModelDetailWindow";
import BreadcrumbsBar from "../global/Breadcrumbs";

const Dashboard = () => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [projectList, setProjectList] = useState([]);
    const [projectName, setProjectName] = useState(null);
    const [nodeId, setNodeId] = useState(null);
    const [graphType, setGraphType] = useState(null);
    const [imageId, setImageId] = useState(null);
    const [collectionTime, setCollectionTime] = useState(null);
    const [aircraftModelList, setAircraftModelList] = useState([]);
    const [expanded, setExpanded] = React.useState([]);
    const [selected, setSelected] = React.useState([]);
    const [breadCrumbsList, setBreadCrumbsList] = useState([]);

    useEffect(() => {
        dispatch(projectActions.getProjectList())
            .then((state) => {
                if (state.payload) {
                    setProjectList(state.payload);
                    if (state.payload && state.payload.length > 0) {
                        setProjectName(state.payload[0].name);
                        setAircraftModelList(null);
                    }
                }
            });
    }, [dispatch]);

    useEffect(() => {
        if (projectName) {

            projectName && dispatch(projectActions.getHierarchy({projectName: projectName}))
                .then((state) => {
                    setAircraftModelList(state.payload);
                    setImageId(null)
                    setCollectionTime(null);
                    setGraphType(null);

                    setNodeId(null);
                    setSelected([]);
                    setExpanded([]);
                });
        }
    }, [dispatch, projectName]);

    useEffect(() => {
        if (projectName) {
            if (nodeId) {
                const nodeType = nodeId.substring(0, 1);
                const getNodeId = (nodeId) => (nodeId ? nodeId.substring(2) : nodeId);
                dispatch(
                    nodeActions.getNodeDetail({
                        projectName,
                        nodeId: getNodeId(nodeId),
                        nodeType,
                    })
                ).then((state) => {
                    if (state.payload) {
                        setBreadCrumbsList(state.payload.breadCrumbsList);
                    }
                })
            } else {
                dispatch(
                    nodeActions.getNodeDetail({
                        projectName,
                        nodeId: undefined,
                        nodeType: "PROJECT"
                    })
                ).then((state) => {
                    if (state.payload) {
                        setBreadCrumbsList(state.payload.breadCrumbsList);
                    }
                }).finally(() => { }
                )
            }
        }
    }, [ projectName, nodeId, dispatch ]);

    const projectSelect = (projectName) => {
        setProjectName(projectName);
        setAircraftModelList([]);
        setExpanded([]);
        setSelected([]);
        setNodeId(null);
        setImageId(null)
        setCollectionTime(null);
        setGraphType(null);
    }

    if (!projectName) {
        return;
    }

    return (
        <div className="app">
            <Sidebar />
            <main className="content">
                <Topbar >
                    <BreadcrumbsBar breadCrumbList={breadCrumbsList}
                                              nodeId={nodeId}
                                              setNodeId={setNodeId}
                                              setSelected={setSelected}
                                              expanded={expanded}
                                              setExpanded={setExpanded}
                    />
                </Topbar>

                <Box sx={{ mb: 1, ml: 1, mr: 1, mt: 1}} >
                    <Box display="grid" gap="10px" gridTemplateColumns="repeat(12, 1fr)" >
                        <Box
                            gridColumn="span 12"
                            gridRow="span 2"
                            sx={{ height: "90vh", width: "100%" }}
                        >

                          <Box
                            display="grid"
                            gridTemplateColumns="repeat(12, 1fr)"
                            sx={{ height: "90vh", width: "100%" }}
                            gap="20px" >

                            <Box
                                gridColumn="span 3"
                                gridRow="span 2"
                                backgroundColor={colors.primary[400]}
                                p="25px"
                                sx={{ height: "90vh", width: "100%" }}
                            >
                                {projectList && projectList.length > 0 && <ProjectSelect projectList={projectList}
                                                                                         projectSelect={projectSelect}
                                                                                         projectName={projectName}/>}

                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    alignItems="left"
                                    mt="25px"
                                >
                                    {aircraftModelList && aircraftModelList.length > 0 &&
                                        <StyledTreeView
                                            aircraftModelList={aircraftModelList}
                                            expanded={expanded}
                                            selected={selected}
                                            setCollectionTime={setCollectionTime}
                                            setExpanded={setExpanded}
                                            setGraphType={setGraphType}
                                            setNodeId={setNodeId}
                                            setSelected={setSelected}
                                        />
                                    }
                                </Box>
                            </Box>

                            <Box
                                gridColumn="span 9"
                                gridRow="span 2"
                                backgroundColor={colors.primary[400]}
                                sx={{ height: "90vh", width: "100%" }}
                            >
                                <Box height="90vh" m="-10px 0 0 0">
                                    {(!nodeId || nodeId.substring(0, 2) !== "C-") &&
                                        <ModelDetailWindow
                                            projectName={projectName}
                                            nodeId={nodeId}
                                            setNodeId={setNodeId}
                                            nodeType={ nodeId ? nodeId.substring(0, 1) : "PROJECT"}
                                            setSelected={setSelected}
                                            expanded={expanded}
                                            setExpanded={setExpanded}
                                        />
                                    }
                                    {nodeId && nodeId.substring(0, 2) === "C-" &&
                                        <DetailWindow
                                            graphType={graphType}
                                            isDashboard={true}
                                            nodeId={nodeId}
                                            nodeType={nodeId.substring(0, 1)}
                                            projectName={projectName}
                                            collectionTime={collectionTime}
                                            setCollectionTime={setCollectionTime}
                                            setGraphType={setGraphType}
                                            setImageId={setImageId}
                                            imageId={imageId}
                                        />
                                    }
                                </Box>
                            </Box>
                          </Box>
                        </Box>
                    </Box>
                </Box>
            </main>
        </div>
    );
};

export default Dashboard;
